import OrchestratedExercise from "./OrchestratedExercise";

class PushUpExercise extends OrchestratedExercise {
	constructor() {
		super("Push Up", "15:00");
	}

	async orchestrate() {
		this.setImages(
			"/imagery/pushups/PushUpEndposition.webp",
			"/imagery/pushups/PushUps.avif",
			"/imagery/pushups/PushUpHands.jfif",
			"/imagery/pushups/PushUpArms.jfif"
		);

		await this.untilRepSet(10, 10);
	}
}

class LateralRaiseExercise extends OrchestratedExercise {
	sets = 0;

	constructor(sets, targetTime) {
		super("Lateral Raise", targetTime);
		this.sets = sets;
	}

	async orchestrate() {
		this.setImages(
			//"/imagery/lateralraises/LateralRaiseCgi.jpg",
			//"/imagery/lateralraises/LateralRaiseHold.png"
			"/imagery/lateralraises/LateralRaise.avif"
		);

		await this.untilRepSet(17, this.sets);
	}
}

class CrunchExercise extends OrchestratedExercise {
	constructor() {
		super("Crunch", "15:00");
	}

	async orchestrate() {
		this.setImages(
			"/imagery/crunches/Crunches.png"
		);

		await this.untilRepSet(30, 4);
	}
}

class ReversePushUpExercise extends OrchestratedExercise {
	sets = 0;

	constructor(sets, targetTime) {
		super("Reverse Push Up", targetTime);
		this.sets = sets;
	}

	async orchestrate() {
		this.setImages(
			"/imagery/reversepushups/ChairReversePushUp.avif",
			"/imagery/reversepushups/ChairReversePushUp.png"
		);

		await this.untilRepSet(25, this.sets);
	}
}

class PlankingExercise extends OrchestratedExercise {
	constructor(sets, targetTime) {
		super("Planking", targetTime, false, sets);
	}

	async orchestrate() {
		this.setImages(
			"/imagery/plankings/Planking.jfif"
		);

		for (let i = 0; i < this.defaultSets; i++) {
			await this.untilTargetTime();

			if (i < this.defaultSets - 1) {
				await this.untilBreak(15);
			}
		}
	}
}

class SquatExercise extends OrchestratedExercise {
	constructor() {
		super("Squat", "05:00");
	}

	async orchestrate() {
		this.setImages(
			"/imagery/squats/Squats.avif",
			"/imagery/squats/SquatNoBendy.jfif",
			"/imagery/squats/SquatCorrect.jpg"
		);

		await this.untilRepSet(30, 4);
	}
}

// https://www.youtube.com/watch?v=i1tjJGGcoYs
class PostureExercise extends OrchestratedExercise {
	multiplier = 1;

	constructor(targetTime, multiplier = 1) {
		super("Posture", targetTime);
		this.multiplier = multiplier;
	}

	async orchestrate() {
		this.setName("Posture Rots");
		this.setImages("/imagery/postures/ThoracicRotations.png");
		await this.untilRepSet(5 * this.multiplier, 2);

		this.setName("Posture Pose");
		this.setImages("/imagery/postures/CobraPose.png");
		await this.untilRepSet(5 * this.multiplier, 2);

		this.setName("Posture OaB");
		this.setImages(
			"/imagery/postures/OverAndBacks.png",
			"/imagery/postures/OverAndBacksCircle.png",
			"/imagery/postures/OverAndBacksDiff.png"
		);
		await this.untilRepSet(5 * this.multiplier, 1);

		this.setName("Posture RevWall");
		this.setImages("/imagery/postures/ReverseWallAngles.png");
		await this.untilRepSet(5 * this.multiplier, 1);

		this.setName("Posture Chin");
		this.setImages("/imagery/postures/ChinTucks.png");
		await this.untilRepSet(3 * this.multiplier, 1);
	}
}

const EXERCISES = [
	// Warmup
	new PostureExercise("05:00", 1),
	new LateralRaiseExercise(2, "05:00"),
	new ReversePushUpExercise(3, "05:00"),

	// Real Shit
	new CrunchExercise(),
	new PushUpExercise(),

	new SquatExercise(),
	new PlankingExercise(3, "00:30"),

	new PostureExercise("10:00", 2),
	new LateralRaiseExercise(3, "10:00")
];
export default EXERCISES;
