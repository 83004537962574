import Masonry from "react-masonry-css";

export default function Explainer({exerciseData}) {
	const getContent = () => {
		if (!exerciseData.content) {
			return (<></>);
		}

		switch (exerciseData.content.type) {
			case "IMAGES": {
				const images = exerciseData.content.images;

				if (images.length === 1) {
					return (
						<img src={images[0]} alt="Loading"/>
					);
				}

				return (
					<Masonry className="images-masonry">
						{images.map(imgUrl => <img key={imgUrl} src={imgUrl} alt="Loading"/>)}
					</Masonry>
				);
			}
			default:
				return (<></>);
		}
	};

	return (
		<div className="explainer">
			{getContent()}
		</div>
	);
}