import {Fab} from "@mui/material";
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useDispatch, useSelector} from "react-redux";
import {selectPlaying, togglePlaying} from "../States";
import TopInfo from "./TopInfo";
import moment from "moment";

export default function Exercise({exerciseData, incrementReps, adjustExerciseIndex}) {
	const dispatch = useDispatch();
	const playing = useSelector(selectPlaying);

	const togglePlayingClick = () => {
		dispatch(togglePlaying());
	};

	return (
		<div className="exercise middleborder" data-done={exerciseData.done ? "yes" : "no"}>
			<TopInfo/>

			<div className="exerciseInfo">
				<h1>{exerciseData.setTarget}x {exerciseData.name}</h1>

				<h2 className="timeTexts">
					<span>{moment.utc(moment.duration(exerciseData.timeSpent, "seconds").asMilliseconds()).format("mm:ss")} / {moment.utc(moment.duration(exerciseData.timeTarget, "seconds").asMilliseconds()).format("mm:ss")}</span>
				</h2>

				{exerciseData.showReps &&
					<h2 className="repSets">
						<span className="setText">{exerciseData.set}x</span>

						<span className="repText">{exerciseData.repetition} / {exerciseData.repetitionTarget}</span>
					</h2>
				}

				{exerciseData.timeSpentIncreaseDuringPause && <h2>INTERMISSION</h2>}
			</div>

			<div className="bottomActions">
				{exerciseData.showReps &&
					<>
						<Fab color="primary" onClick={() => {
							incrementReps(1)
						}}>
							+1
						</Fab>
						<Fab color="primary" onClick={() => {
							incrementReps(2)
						}}>
							+2
						</Fab>
						<Fab color="primary" onClick={() => {
							incrementReps(5)
						}}>
							+5
						</Fab>
						<Fab color="primary" onClick={() => {
							incrementReps(10)
						}}>
							+10
						</Fab>
					</>
				}

				<div className="rowActions">
					<Fab color="primary" onClick={() => {
						adjustExerciseIndex(-1)
					}}>
						<ChevronLeftIcon/>
					</Fab>
					<Fab color="primary" onClick={togglePlayingClick}>
						{playing ? <PauseIcon/> : <PlayArrowIcon/>}
					</Fab>
					<Fab color="primary" onClick={() => {
						adjustExerciseIndex(1)
					}}>
						<ChevronRightIcon/>
					</Fab>
				</div>
			</div>
		</div>
	);
}