import {useEffect, useState} from "react";
import moment from "moment";
import {useSelector} from "react-redux";
import {selectPlaying} from "../States";

export default function TopInfo() {
	const [time, setTime] = useState("00:00");
	const [timeSec, setTimeSec] = useState("00");
	const [spentTime, setSpentTime] = useState(0);
	const [realSpentTime, setRealSpentTime] = useState(0);

	const playing = useSelector(selectPlaying);

	useEffect(() => {
		let realSecs = 0;

		const timer = setInterval(() => {
			setTime(moment().format("HH:mm"));
			setTimeSec(moment().format("ss"));

			realSecs += 0.5;
			setRealSpentTime(realSecs);
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);

	useEffect(() => {
		const spentTimer = setTimeout(() => {
			if (playing) {
				setSpentTime(spentTime + 1);
			}
		}, 1000);

		return () => {
			clearTimeout(spentTimer);
		};
	}, [playing, spentTime]);

	return (
		<div className="topInfo">
			<strong>{time}</strong>:{timeSec} ({moment.utc(moment.duration(spentTime, "seconds").asMilliseconds()).format("HH:mm:ss")} / {moment.utc(moment.duration(realSpentTime, "seconds").asMilliseconds()).format("HH:mm:ss")})
		</div>
	);
}