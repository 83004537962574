import React, {useEffect} from 'react';
import './App.css';
import Explainer from "./components/Explainer";
import Exercise from "./components/Exercise";
import {useDispatch, useSelector} from "react-redux";
import {
	addExerciseReps, modifyExercise,
	selectExerciseIndex,
	selectExercises,
	selectPlaying,
	setExercises, setIndex
} from "./States";
import {WORKOUTS} from "./exercises/Workouts";

const workoutName = window.location.hash;
let workout = null;
if (workoutName && workoutName.length > 1) {
	workout = WORKOUTS[workoutName.substring(1)];
}
if (!workout) {
	alert("Workout not set or not found! Falling back to default.");
	workout = WORKOUTS["default"];
}

export default function App() {
	const playing = useSelector(selectPlaying);
	const dispatch = useDispatch();

	useEffect(() => {
		document.body.setAttribute("data-theme", playing ? "light" : "dark");
	}, [playing]);

	const exercises = useSelector(selectExercises);
	const exerciseIndex = useSelector(selectExerciseIndex);
	const exercise = (exercises && exercises[exerciseIndex]) ? exercises[exerciseIndex] : {
		name: "None"
	};

	const incrementReps = amount => {
		dispatch(addExerciseReps({exerciseIndex, amount}));
	};
	const adjustExerciseIndex = diff => {
		let newIndex = exerciseIndex + diff;
		if (newIndex < 0) {
			newIndex = workout.length - 1;
		} else if (newIndex >= workout.length) {
			newIndex = 0;
		}

		dispatch(setIndex(newIndex));
	};

	// Exercises Initializer
	useEffect(() => {
		dispatch(setExercises(workout.map(exercise => {
			return {
				name: exercise.name,
				repetition: 0,
				repetitionTarget: 0,
				set: 0,
				setTarget: exercise.defaultSets,
				content: {type: "NONE"},
				timeSpent: 0,
				timeTarget: exercise.targetSec,
				timeSpentIncreaseDuringPause: false,
				showReps: exercise.showReps
			};
		})));

		let i = 0;
		for (const workoutElement of workout) {
			workoutElement.dispatch = dispatch;
			workoutElement.exerciseIndex = i++;
			workoutElement.orchestrated = false;
		}
	}, [dispatch]);

	useEffect(() => {
		workout[exerciseIndex]._orchestrate();
	}, [exerciseIndex]);

	useEffect(() => {
		const exerciseSpentTimer = setInterval(() => {
			if (playing || exercises[exerciseIndex].timeSpentIncreaseDuringPause) {
				dispatch(modifyExercise({
					exerciseIndex,
					modifier: exercise => {
						exercise.timeSpent++;
					}
				}));
			}
		}, 1000);

		return () => {
			clearInterval(exerciseSpentTimer)
		};
	}, [dispatch, playing, exerciseIndex, exercises]);

	return (
		<div className="app">
			<Explainer exerciseData={exercise}/>
			<Exercise exerciseData={exercise} incrementReps={incrementReps} adjustExerciseIndex={adjustExerciseIndex}/>
		</div>
	);
}
