import {configureStore, createSlice} from "@reduxjs/toolkit";

const exercisesSlice = createSlice({
	name: "exercises",
	initialState: [{
		name: "Test",
		repetition: 0,
		repetitionTarget: 4,
		set: 0
	}],
	reducers: {
		setExercises: (state, action) => {
			return action.payload;
		},
		addExerciseReps: (exercises, action) => {
			const {exerciseIndex, amount} = action.payload;

			if (exerciseIndex >= exercises.length || exerciseIndex < 0) {
				return exercises;
			}

			const exercise = exercises[exerciseIndex];
			exercise.repetition += amount;

			/*while (exercise.repetition >= exercise.repetitionTarget) {
				exercise.set++;
				exercise.repetition = exercise.repetition - exercise.repetitionTarget;

				if (exercise.repetitionTarget === 0) {
					exercise.repetition = 0;
					break;
				}
			}*/

			if (exercise.repetition >= exercise.repetitionTarget) {
				exercise.repetition = 0;
				exercise.set++;
			}

			return exercises;
		},
		modifyExercise: (exercises, action) => {
			const {exerciseIndex, modifier} = action.payload;

			if (exerciseIndex >= exercises.length || exerciseIndex < 0) {
				return exercises;
			}

			modifier(exercises[exerciseIndex]);
			return exercises;
		}
	}
});

const exerciseIndexSlice = createSlice({
	name: "exerciseIndex",
	initialState: 0,
	reducers: {
		setIndex: (state, action) => {
			return action.payload;
		},
		adjustIndex: (state, action) => {
			return state + action.payload;
		}
	}
});

const playingSlice = createSlice({
	name: "playing",
	initialState: false,
	reducers: {
		togglePlaying: (state) => {
			return !state;
		},
		setPlaying: (state, action) => {
			return action.payload;
		}
	}
});

export const {setExercises, addExerciseReps, modifyExercise} = exercisesSlice.actions;
export const {setIndex, adjustIndex} = exerciseIndexSlice.actions;
export const {togglePlaying, setPlaying} = playingSlice.actions;

export const selectExercises = state => state.exercises;
export const selectExerciseIndex = state => state.exerciseIndex;
export const selectPlaying = state => state.playing;

export const store = configureStore({
	reducer: {
		exercises: exercisesSlice.reducer,
		exerciseIndex: exerciseIndexSlice.reducer,
		playing: playingSlice.reducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActionPaths: ['payload.modifier'],
			},
		}),
});
