import {modifyExercise, setPlaying, store} from "../States";
import moment from "moment";

// Der erste Aufruf wird ein Griff in das Klo
let klogriff = false;

export default class OrchestratedExercise {
	name = "None";
	showReps = true;
	targetSec = 0;
	defaultSets = 0;
	dispatch = null;
	exerciseIndex = 0;

	constructor(name, targetTime, showReps = true, defaultSets = 0) {
		this.name = name;
		this.showReps = showReps;
		this.targetSec = moment.duration("00:" + targetTime).asSeconds();
		this.defaultSets = defaultSets;
	}

	orchestrated = false;

	async _orchestrate() {
		if (!klogriff) {
			klogriff = true;
			return;
		}
		if (this.orchestrated) {
			return;
		}

		this.orchestrated = true;
		await this.orchestrate();

		this.modifyMe(exercise => {
			exercise.done = true;
		});
	}

	// Overwrite this
	async orchestrate() {
	}

	modifyMe(modifier) {
		this.dispatch(modifyExercise({
			exerciseIndex: this.exerciseIndex,
			modifier
		}));
	}

	subscribeStore(checker, wantPlaying = false) {
		let unsubscribe = store.subscribe(() => {
			const exercises = store.getState().exercises;

			if (exercises) {
				const exercise = exercises[this.exerciseIndex];

				if (exercise) {
					if (checker(exercise, wantPlaying ? store.getState().playing : undefined)) {
						unsubscribe();
					}
				}
			}
		});
	}

	setImages(...urls) {
		this.modifyMe(exercise => {
			exercise.content = {
				type: "IMAGES",
				images: urls
			}
		});
	}

	setName(name) {
		this.name = name;
		this.modifyMe(exercise => {
			exercise.name = name;
		});
	}

	wait(milliseconds) {
		return new Promise(fulfill => {
			setTimeout(() => fulfill(1), milliseconds);
		});
	}

	untilRepSet(repTarget, setTarget) {
		return new Promise(fulfill => {
			this.modifyMe(exercise => {
				exercise.repetition = 0;
				exercise.set = 1;
				exercise.repetitionTarget = repTarget;
				exercise.setTarget = setTarget;
			});

			this.subscribeStore(exercise => {
				if (exercise.set > exercise.setTarget) {
					fulfill(1);
					return true;
				}

				return false;
			});
		});
	}

	untilTargetTime(targetSec = null) {
		return new Promise(fulfill => {
			this.modifyMe(exercise => {
				exercise.timeSpent = 0;
				exercise.timeTarget = targetSec || this.targetSec || exercise.timeTarget;
			});

			this.subscribeStore(exercise => {
				if (exercise.timeSpent >= exercise.timeTarget) {
					fulfill(1);
					return true;
				}

				return false;
			});
		});
	}

	untilBreak(breakTimeSec) {
		return new Promise(fulfill => {
			this.dispatch(setPlaying(false));
			this.modifyMe(exercise => {
				exercise.timeSpent = 0;
				exercise.timeTarget = breakTimeSec;
				exercise.timeSpentIncreaseDuringPause = true;
			});

			this.subscribeStore((exercise, playing) => {
				if (playing) {
					setTimeout(() => {
						this.modifyMe(exercise => {
							exercise.timeSpentIncreaseDuringPause = false;
						});
					}, 100);

					fulfill(1);
					return true;
				}

				return false;
			}, true);
		});
	}
};
